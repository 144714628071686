const footer = document.getElementById('footer');
const scrollBtn = document.getElementById('scroll');
const pageTopBtn = document.getElementById('pageTop');
const scrollBtnChange = () => {
	if (footer.getBoundingClientRect().top < window.innerHeight) {
		scrollBtn.classList.add('hide');
		pageTopBtn.classList.add('show');
	} else {
		scrollBtn.classList.remove('hide');
		pageTopBtn.classList.remove('show');
	}
}

window.addEventListener('scroll', scrollBtnChange, false);

const cursor = (() => {
	const props = {
		status: 0,
		statusArr: ['normal', 'link', 'prev', 'next'],
		textArr: ['', 'Lean more', 'Prev', 'Next'],
		elm: document.querySelector('.cursor'),
	};
	if (!props.elm) return;
	props.textElm = props.elm.querySelector('.js_cursorText');
	return {
		setStatus: (s) => {
			props.status = s;
		},
		getStatus: () => {
			return props.statusArr[props.status];
		},
		getStatusNum: () => {
			return props.status;
		},
		changeCursor: () => {
			props.textElm.innerHTML = props.textArr[props.status];
			props.elm.classList.remove('link', 'prev', 'next');
			props.elm.classList.add(props.statusArr[props.status]);
		},
		resetCursor: () => {
			props.elm.classList.remove('link', 'prev', 'next');
		},
		moveCursor: (top, left) => {
			props.elm.style.top = top + 'px';
			props.elm.style.left = left + 'px';
		},
	};
})();

const gallerySliderWrap = document.getElementById('gallerySliderWrap');

const swingSlider = (elm, e) => {
	cursor.setStatus(1);

	if (0 < elm.getBoundingClientRect().right - window.innerWidth + 80) {
		gallerySliderWrap.classList.add('next');
		// cursor.setStatus(3);
		cursor.setStatus(1);
	} else if (elm.getBoundingClientRect().left < 0) {
		gallerySliderWrap.classList.add('prev');
		// cursor.setStatus(2);
		cursor.setStatus(1);
	} else {
		gallerySliderWrap.classList.remove('next', 'prev');
		cursor.setStatus(1);
	}
	cursor.moveCursor(e.clientY, e.clientX);
	cursor.changeCursor();
};

const resetPosSlider = (e) => {
	// e.toElementが存在するかをチェックし、存在する場合のみクラスを確認する
	if (e && e.toElement && e.toElement.classList && e.toElement.classList.contains('cursor')) {
		return false;
	}

	// gallerySliderWrapが存在する場合のみクラスを削除
	if (gallerySliderWrap) {
		gallerySliderWrap.classList.remove('next', 'prev');
	}

	// カーソルのステータスをリセット
	cursor.setStatus(0);
	cursor.resetCursor();
};

const initSlider = (e) => {
	const sliderObj = e;
	e.slides.forEach((elm, i) => {
		elm.addEventListener('mouseenter', (e) => {
			swingSlider(elm, e);
		}, false);
		elm.addEventListener('mouseleave', resetPosSlider, false);

		// elm.addEventListener('click', (e) => {
		// 	const cursorStatus = cursor.getStatusNum();
		// 	if (cursorStatus < 2) {
		// 		return;
		// 	} else {
		// 		e.preventDefault();
		// 	}
		// 	if (cursorStatus === 2) {
		// 		sliderObj.slidePrev();
		// 	}
		// 	if (cursorStatus === 3) {
		// 		sliderObj.slideNext();
		// 	}
		// }, false);
	});

	// .gallerySlider__image のクリックイベントを設定
	document.querySelectorAll('.gallerySlider__image').forEach(function (image) {
		image.addEventListener('click', function () {
			// すでに 'open' クラスがついている要素を探して削除
			let currentOnElements = document.querySelectorAll('.open');
			currentOnElements.forEach(function (el) {
				el.classList.remove('open');
			});

			// クリックされた要素の次に来る要素に 'open' クラスを追加
			let nextElement = image.nextElementSibling;
			if (nextElement) {
				nextElement.classList.add('open');
			}
		});
	});

	// .gallerySlider__image の次に来る要素にクリックイベントを設定して、on クラスを削除する
	document.querySelectorAll('.gallerySlider__image + *').forEach(function (element) {
		element.addEventListener('click', function () {
			if (element.classList.contains('open')) {
				element.classList.remove('open');
			}
		});
	});

	document.body.addEventListener('mousemove', (e) => {
		if (e.target.closest('.js_modal') !== null || e.target.classList.contains('.js_modal')) {
			cursor.moveCursor(e.clientY, e.clientX);
		} else {
			resetPosSlider();
		}
	}, false);
};

const gallerySlider = new Swiper('.gSlider', {
	loop: false,
	slidesPerView: 'auto',
	spaceBetween: 20,
	speed: 1000,
	preventClicks: false,
	preventClicksPropagation: false,
	navigation: {
		prevEl: '.gSlider-btn-prev',
		nextEl: '.gSlider-btn-next',
	},
	breakpoints: {
		480: {
			spaceBetween: 50,
		}
	},
	on: {
		init: initSlider,
	}
});